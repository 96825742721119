import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Usuario } from 'src/app/interfaces/usuario.interface';
import { environment } from 'src/environments/environment';

interface OpcionMenu {
  ruta: string;
  texto: string;
}
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})


export class NavbarComponent implements OnInit{


  get mostrarMantenimientos(){
    const usuario: Usuario = environment.usuario;
    return (usuario.nivel! > environment.nivelMenuMantenimientos)?true: false;
  }

  get mostrarInformes(){
    const usuario: Usuario = environment.usuario;
    return (usuario.nivel! > environment.nivelMenuInformes)?true: false;
  }
 

 


  public menuMantenimientos:OpcionMenu[] = [
    {ruta: '/reservado/manCargos', texto: 'Mantenimiento de Cargos'},
    {ruta: '/reservado/tiposHora', texto: 'Mantenimiento de Tipos Hora'},
    {ruta: '/reservado/festivos', texto: 'Festivos'},
    {ruta: '/reservado/manUsuarios/0', texto: 'Mantenimiento Usuarios'},
    {ruta: '/mantenimientos/vacaciones', texto: 'Vacaciones'},
    {ruta: '/mantenimientos/asuntos', texto: 'Asuntos Propios'},
    {ruta: '/reservado/empleadosMargen', texto: 'Autorizados modificar horas'},
    {ruta: '/reservado/bloqueados', texto: 'Desbloquear Usuarios'},
    {ruta: '/reservado/configuracion', texto: 'Configuración'},
  ];

  public dividersMantenimientos: number[] = [
    2,5,7
  ];


  public menuHoras:OpcionMenu[] = [
    {ruta: '/horas/selCliente', texto: 'Seleccion de Cliente'},
    {ruta: '/horas/consultaHoras', texto: 'Consulta de Horas'},
  ];

  public dividersHoras: number[] = [
    // 1,4
  ];



  public menuInformes:OpcionMenu[] = [
    {ruta: '/informes/detalleCliente', texto: 'Detalle Horas Cliente'},
    {ruta: '/informes/horasEmpleados', texto: 'Horas Empleados'},
    {ruta: '/informes/proyectos', texto: 'Informe Proyectos'},
    {ruta: '/informes/rentProyectos', texto: 'Rentabilidad Proyectos'},
    {ruta: '/informes/controlHoras', texto: 'Control de Horas'},
  ];

  public dividersInformes: number[] = [
    3,
  ];

  public menuUtilidades:OpcionMenu[] = [
    // {ruta: '/utilidades/login', texto: 'Login'},
    {ruta: 'login', texto: 'Login'},
    {ruta: 'password/0', texto: 'Cambiar Contraseña'},
    // {ruta: '/utilidades/logout', texto: 'Logout'},
    // {ruta: '/utilidades/galeria', texto: 'Galería de Imágenes'},
    // {ruta: '/utilidades/upload', texto: 'Subir Imágenes'},
    // {ruta: '/utilidades/reserva', texto: 'Reserva de Mesas'},
    // {ruta: '/utilidades/confMesas', texto: 'Configuración de Mesas'},
    // {ruta: '/utilidades/confTactil/bar', texto: 'Configuración Táctil'},
    // {ruta: '/utilidades/fichaje', texto: 'Registro actividad'},
    // {ruta: '/utilidades/consultaFichadas', texto: 'Consulta Fichadas'},
    // {ruta: '/utilidades/lectorDocumentos', texto: 'Lector Documentos'},
    // {ruta: '/utilidades/preferencias', texto: 'Preferencias'},
    // {ruta: '/utilidades/ayuda', texto: 'Ayuda'},
    // {ruta: '/utilidades/desarrollo', texto: 'Opcs. Desarrollo'},
  ];

  public dividersUtilidades: number[] = [
    // 0,2,4,5,6
  ];
  
  constructor(private route:Router) { }

  ngOnInit(): void {
    
  }

  

}
