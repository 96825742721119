import { AfterViewInit, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Mensaje } from 'src/app/interfaces/mensaje.interface';
import { Respuesta } from 'src/app/interfaces/respuesta.interface';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { TabDirective } from 'src/app/shared/directives/tab.directive';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {

  // NECESARIO PARA ACTIVAR TABULADOR
  @ViewChildren(TabDirective) inputs!: QueryList<TabDirective>;

  public tituloForm:string = 'Login';
  public textoModal:string = '';
  public nombreUsuarioActivo: string = '';
  public activarLogout: boolean = false;
  // public botonModal:any;
  public mensaje:Mensaje={};

  public textoBoton = 'login';
  public activarSpinner:boolean = false;
  public login:boolean = true;
  public activarDemo:boolean = false;
  public versionBD = 0.001;
  public versionFr = 1.019;

  public formulario:FormGroup = this.fb.group({
      usuario: ['',[Validators.required, Validators.minLength(4)],],
      password: ['',[Validators.required, Validators.minLength(4)]],
    },
    {
    },);

  constructor(
              private fb:FormBuilder,
              private router: Router,
              private usuariosService:UsuariosService,
  ) { }

  ngOnInit(): void {
    if (this.activarDemo){this.setearDemo();}
    this.cargarVersion();
  }

  ngAfterViewInit(): void {
    this.initTab();
    this.comprobarToken();
  }

  initTab(){
    const controls=this.inputs.toArray();
    for (let i=0;i<controls.length-1;i++){
      controls[i].nextControl=controls[i+1].self;
    }
  }

  cargarVersion(){
    this.usuariosService.getVersion()
                        .subscribe(
                          resp => {
                            if(resp.status != 200) {
                              this.mensaje.textoAlert = resp.detalle;
                              this.mensaje.alert = true;
                            }else {
                              this.versionBD = resp.detalle.version;
                              if (this.versionFr < this.versionBD){
                                this.mensaje.textoDanger = 'El programa necesita actualizarse, por favor borre su historial de navegación o contacte con gestionAngular antes de continuar.';
                                this.mensaje.persistente = true;
                                this.mensaje.danger = true;
                              }
                            }  
                            this.activarSpinner = false;
                          }
                        );

  }

  /* -------------------------------------------------------------------------- */
  /*                           NECESARIO PARA MENSAJE                           */
  /* -------------------------------------------------------------------------- */

  controlBotones(evento:number){
    this.mensaje.botones = false;
    this.mensaje.persistente = false;
    if (evento == 1){
      eval(this.mensaje.comandoAceptar!);
    }else{
      eval(this.mensaje.comandoCancelar!);
    }
  }


  get spinner():boolean{
    return this.activarSpinner;
  }

   guardar(){
    
    if (this.login){
        this.activarSpinner = true;
        if (this.formulario.invalid) {
          return Object.values(this.formulario.controls).forEach(control => {
            if (control instanceof FormGroup) { 
              return Object.values(control.controls).forEach(control => {control.markAsTouched()})
            }else{
            control.markAsTouched();
        }});
        }else{
          this.usuariosService.login(this.formulario.get('usuario')!.value, this.formulario.get('password')!.value)
                              .subscribe(async resp =>{
                                this.activarSpinner = false;
                                if(resp.status =="200"){
                                    // this.mensaje.textoSuccess = `Validación correcta. Pulse F5 o Refresque la página para continuar.`;
                                    localStorage.setItem('tp-Token', resp.detalle);
                                    localStorage.setItem('tp-Usuario', this.formulario.get('usuario')!.value);
                                    const caducado: boolean = await this.comprobarCaducidadPassword();
                                    if (caducado == false){
                                        this.mensaje.persistente = true;
                                        this.textoBoton = 'logout'
                                        this.login = false;
                                        // this.cargarClientes();
                                        // this.cargarArticulos();
                                        // this.mensaje.success = true;
                                        this.mensaje.refresh = true;
                                    }
                                   
                                    
                                }else{
                                    this.mensaje.textoDanger = `Error: ${resp.status}, ${resp.detalle}`;
                                    // this.mensaje.botones = false;
                                    this.mensaje.danger = true;
                                    // this.textoModal = `Error: ${resp.status}, ${resp.detalle}`;
                                }
                                // botonModal?.click();
                                
                              });
                            }  
    }else {
      // localStorage.removeItem('tp-Token');
      // localStorage.removeItem('tp-Usuario');
      // this.login = true;
      // this.textoBoton = 'login';
      // this.formulario.reset();
      // this.mensaje.textoSuccess = 'Desconectado con éxito';
      // this.mensaje.success = true;
      this.logout();
    }
    
  }

  async comprobarCaducidadPassword(): Promise<boolean>{
    
    const resp: Respuesta = await this.usuariosService.getFechaPassword(this.formulario.get('usuario')!.value).toPromise();
    if(resp.status != 200) {
        console.log(resp.detalle);
    }else{
       const fechaCaducidadPassword: number = Number(resp.detalle.fecha) + 7776000; //(90 días * 24 horas * 60 minutos * 60 segundos)
       const hoy: number = new Date().getTime() / 1000;
       
       
       if (hoy > fechaCaducidadPassword) {
        this.mensaje.textoDanger = 'El password ha caducado, por favor cambie su contraseña.';
        this.mensaje.persistente = true;
        this.mensaje.botones = true;
        this.mensaje.comandoCancelar = 'this.botonCancelar();';
        this.mensaje.comandoAceptar = 'this.irPassword();';
        this.mensaje.danger = true;
        
        return true;
       }
    }
    
    return false;
  }

  botonCancelar(){
    localStorage.removeItem('tp-Token');
    localStorage.removeItem('tp-Usuario');
    this.mensaje.danger = false;
  }

  async irPassword(){
    const resp: Respuesta = await this.usuariosService.getIdUsuario(this.formulario.get('usuario')!.value).toPromise();
    if (resp.status != 200) {
      console.log(resp.detalle);
    }else{
      this.router.navigateByUrl(`password/${resp.detalle.id}`);
    }
  }
 
  campoNoValido( campo: string){
    return (this.formulario.controls[campo].errors && this.formulario.controls[campo].touched)
  }

  comprobarToken(){
   const token = localStorage.getItem('tp-Token');
   const usuario = localStorage.getItem('tp-Usuario');
   
   if (!token || !usuario){
    //  console.log('No hay token o usuario');
    // if (this.activarDemo){this.setearDemo();}
   }else{
    //  console.log('compruebo token');



     this.usuariosService.compruebaToken()
                         .subscribe(resp =>{
                         if (resp.status !=200) {
                            this.mensaje.textoDanger = `Error: ${resp.status}, ${resp.detalle}`;
                            this.mensaje.danger = true;
                            
                            this.logout();
                         }else{
                          this.textoBoton = 'logout';
                          this.login = false;
                           this.formulario.reset({
                             usuario: usuario
                           });
                           this.nombreUsuarioActivo = this.formulario.get('usuario')!.value;
                           this.activarLogout = true;
                          if (this.activarDemo){this.ponerBarraFija();}
                          this.comprobarCaducidadPassword();

                         }
                         });
   }
   
   
   
 }
  
  logout(){
      this.activarLogout = false;
      
      localStorage.removeItem('tp-Token');
      localStorage.removeItem('tp-Usuario');
      // environment.token = '';
      // environment.usuario = '';
      this.login = true;
      this.textoBoton = 'login';
      this.formulario.reset();
      this.mensaje.textoSuccess = 'Desconectado con éxito';
      this.mensaje.success = true;
      if (this.activarDemo){this.setearDemo();}
  }

  setearDemo(){
    
    this.formulario.reset({
      usuario: 'demo',
      password: 'demo'
    })
  }

  ponerBarraFija(){
      setTimeout(() => {
        localStorage.setItem('fijarBarra', 'true');
      }, 2000);
  }

  
}
