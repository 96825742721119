// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // base_url: 'http://localhost/horas-rf/public',

  base_url: 'https://ahora.gestionangular.net/horas-rf/public',



  /* -------------------------------------------------------------------------- */
  /*                             BASES    CLIENTES                              */
  /* -------------------------------------------------------------------------- */
  // base_url: 'https://fg.gestionangular.es/horas-rf/public',


  marcaFinal: 'final', //Es la palabra clave para que codIgniter ignore los parámetros 'hasta'. Lógicamente también debe estar definida como constante en codeIgniter.
  clientes: [],
  articulos: [],
  noImage: 'assets/noimage.png',
  almacenDefecto: 1,
  tarifaDefecto: 1,
  timeOutDefecto: 2000,
  activarVoz: false,
  gmt: "",
  usuario: {},




  /* -------------------------------------------------------------------------- */
  /*                                  ARTICULOS                                 */
  /* -------------------------------------------------------------------------- */

  

  /* -------------------------------------------------------------------------- */
  /*                                  CLIENTES                                  */
  /* -------------------------------------------------------------------------- */

  codigoClienteAutomatico: false,
  

  /* -------------------------------------------------------------------------- */
  /*                                    MENUS                                   */
  /* -------------------------------------------------------------------------- */


  nivelMenuMantenimientos: 75,
  nivelMenuInformes: 75,
  


  /* -------------------------------------------------------------------------- */
  /*                                   MODULOS                                  */
  /* -------------------------------------------------------------------------- */


  


  /* -------------------------------------------------------------------------- */
  /*                      TICKETS FACTURAS PRESUPUESTOS Y ALBARANES             */
  /* -------------------------------------------------------------------------- */
  
  
  imprimirAlGrabar: false,
 
 
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


